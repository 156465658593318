import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DetectSessionTimeOut = () => {
  const navigate = useNavigate();
  let timeout: any;

  const logout = () => {
    // Clear token and redirect to login
    //localStorage.removeItem("token"); // Or sessionStorage
    navigate("/auth/logout/inactive"); // Redirect user to login page
  };

  const resetTimer = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      logout(); // Trigger logout on timeout
    }, 15 * 60 * 1000); // 5 minutes of inactivity
  };

  useEffect(() => {
    // Attach event listeners for activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);
    window.addEventListener("click", resetTimer);

    // Start the inactivity timer
    resetTimer();

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keypress", resetTimer);
      window.removeEventListener("click", resetTimer);
      clearTimeout(timeout);
    };
  }, []);

  return null; // No UI component, just the logic
};

export default DetectSessionTimeOut;
