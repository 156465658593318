import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface AccountLayoutProps {
  bottomLinks?: any;
  children?: any;
  layoutTitle?: string;
}

const AuthLayout = ({
  bottomLinks,
  children,
  layoutTitle,
}: AccountLayoutProps) => {
  //
  const { t } = useTranslation();

  useEffect(() => {
    if (document.body) document.body.classList.add("authentication-bg");

    return () => {
      if (document.body) document.body.classList.remove("authentication-bg");
    };
  }, []);

  return (
    <>
      <div className="account-pages " >
        <Container>
          <Row className="justify-content-center ">
            <Col xl={6} className=" mt-5 ">
              <Card
                style={{
                  border: "8px solid white",
                  borderRadius: "15px",
                  backgroundColor: "#0c1b5b79",

                }}
              >
                <Card.Body className="p-0" >
                  <Row className="g-0">
                    <Col lg={12} className="p-4 ">
                      {children}
                    </Col>

                    {/* <Col
                      lg={6}
                      className="d-none d-md-inline-block"
                      style={{ border: "2px solid red" }}
                    >
                      <div className="auth-page-sidebar">
                        <div className="overlay"></div>
                        <div className="auth-user-testimonial">
                          <p className="fs-24 fw-bold text-white mb-1">
                            {t(`${layoutTitle ? layoutTitle : ""}`)}
                          </p>
                          <p className="lead">"{t("")}"</p>
                          <p>- {t("")}</p>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>

              {/* bottom links */}
              {bottomLinks}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AuthLayout;
