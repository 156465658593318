import React, { useState, useRef, useCallback, useEffect } from "react";
import { Modal, Button, Row, Form, Col, Alert } from "react-bootstrap";
import Webcam from "react-webcam";
import imageCompression from "browser-image-compression";
import api from "../../../../utils/ApiMethod";
// import { Form } from "react-router-dom";

const UpdatePlayerAadharImage = ({ id }: any) => {
  // console.log("studnetId-aadhar", id);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [frontImage, setFrontImage] = useState<string | null>(null); // Base64 string for front image
  const [backImage, setBackImage] = useState<string | null>(null); // Base64 string for back image
  const [compressedFrontImage, setCompressedFrontImage] = useState<File | null>(
    null
  ); // File for front image
  const [compressedBackImage, setCompressedBackImage] = useState<File | null>(
    null
  ); // File for back image
  const webcamRef = useRef<Webcam>(null);
  const [errorMessageInAadhar, setErrorMessageInAadhar] = useState("");
  const [facingMode, setFacingMode] = useState<"user" | "environment">("user");

  // Show the modal

  const handleShow = () => setShowModal(true);

  // Hide the modal
  const handleClose = () => {
    setShowModal(false);
    setFrontImage(null);
    setBackImage(null);
    setCompressedFrontImage(null);
    setCompressedBackImage(null);
  };

  // Capture front image
  const captureFrontImage = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setFrontImage(imageSrc); // Save front image base64 string
    }
  }, [webcamRef]);

  // Capture back image
  const captureBackImage = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setBackImage(imageSrc); // Save back image base64 string
    }
  }, [webcamRef]);

  // Convert Data URI to File
  const dataURItoFile = (dataURI: string, fileName: string): File => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], fileName, { type: mimeString }); // Create a File from Blob
  };

  // Compress the captured image
  const compressImage = async (imageSrc: string, isFront: boolean) => {
    try {
      const imageFile = dataURItoFile(
        imageSrc,
        isFront ? "front-image.png" : "back-image.png"
      ); // Convert to File
      const options = {
        maxSizeMB: 0.2, // Max size 200KB
        maxWidthOrHeight: 500, // Max image width or height
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(imageFile, options);
      if (isFront) {
        setCompressedFrontImage(compressedFile); // Save compressed front image
      } else {
        setCompressedBackImage(compressedFile); // Save compressed back image
      }
    } catch (error) {
      console.error("Image compression failed:", error);
    }
  };

  // Submit handler for both images
  const handleSubmit = async () => {
    if (compressedFrontImage && compressedBackImage) {
      const imageDetails = {
        front: {
          name: compressedFrontImage.name,
          size: compressedFrontImage.size,
          type: compressedFrontImage.type,
        },
        back: {
          name: compressedBackImage.name,
          size: compressedBackImage.size,
          type: compressedBackImage.type,
        },
      };
      // console.log("Front Image Details:", imageDetails.front);
      // console.log("Back Image Details:", imageDetails.back);
      const formData = new FormData();
      formData.append("compressedFrontAadharImage", compressedFrontImage);
      formData.append("compressedBackAadharImage", compressedBackImage);
      formData.append("studentId", id);
      const response = await api.updatePatchData(
        `/api/update/aadhar/bothImage`,
        formData
      );
      // console.log("response getting while uploading aadhar live", response);

      if (response.status !== 200) {
        setErrorMessageInAadhar(
          response.message ||
            "Something went wrong in updating player's aadhar."
        );
      }
      if (response.status === 200) {
        window.location.reload();
      }
      //   console.log("response after updating aadhar image.", response);
    } else {
      console.log("Please capture both front and back images.");
    }
  };

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Update Aadhar Images
      </Button>

      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Capture Aadhar Card Images</Modal.Title>
        </Modal.Header>
        {errorMessageInAadhar && <Alert>{errorMessageInAadhar}</Alert>}
        <Modal.Body>
          <Row>
            <Col>
              <>
                <Form.Control
                  as="select"
                  value={selectedDeviceId}
                  onChange={(e) => setSelectedDeviceId(e.target.value)}
                >
                  {devices.map((device, key) => (
                    <option value={device.deviceId} key={key}>
                      {device.label || `Device ${key + 1}`}
                    </option>
                  ))}
                </Form.Control>
                <Button
                  variant="secondary"
                  className="mt-2"
                  onClick={() =>
                    setFacingMode((prev) =>
                      prev === "user" ? "environment" : "user"
                    )
                  }
                >
                  Switch to {facingMode === "user" ? "Back" : "Front"} Camera
                </Button>
                <h3>
                  Capture here
                  {/* Aadhar */}
                </h3>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/png" // Ensure PNG format
                  width="100%"
                  className="mt-2"
                  // videoConstraints={{
                  //   facingMode: "user",
                  // }}

                  videoConstraints={{
                    deviceId: selectedDeviceId || undefined,
                    facingMode,
                  }}
                />
              </>
            </Col>
            <Col>
              <h3>Front side</h3>
              {frontImage && (
                <div className="mt-3">
                  <img src={frontImage} alt="Front" width="100%" />
                </div>
              )}
            </Col>
            <Col>
              <h3>Back side</h3>
              {backImage && (
                <div className="mt-3">
                  <img src={backImage} alt="Back" width="100%" />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <div className="mt-3">
                <Button variant="secondary" onClick={captureFrontImage}>
                  Capture Front Image
                </Button>
              </div>
            </Col>
            <Col sm={4}>
              <div className="mt-3">
                <Button
                  variant="secondary"
                  onClick={captureBackImage}
                  disabled={!frontImage}
                >
                  Capture Back Image
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!compressedFrontImage && !compressedBackImage && (
            <Button
              variant="primary"
              onClick={() => {
                if (frontImage) compressImage(frontImage, true); // Compress front image
                if (backImage) compressImage(backImage, false); // Compress back image
              }}
              disabled={!frontImage || !backImage}
            >
              Compress
            </Button>
          )}
          {compressedFrontImage && compressedBackImage && (
            <Button
              variant="success"
              onClick={handleSubmit}
              disabled={!compressedFrontImage || !compressedBackImage}
              //   className="mt-3"
            >
              Submit Aadhar Images
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Submit Button */}
      {/* <Button
        variant="success"
        onClick={handleSubmit}
        disabled={!compressedFrontImage || !compressedBackImage}
        className="mt-3"
      >
        Submit Aadhar Images
      </Button> */}
    </>
  );
};

export default UpdatePlayerAadharImage;
