// import React, { useState, useRef } from "react";
// import { Modal, Button, Row, Col } from "react-bootstrap";
// import Webcam from "react-webcam";
// import imageCompression from "browser-image-compression";
// import api from "../../../../utils/ApiMethod";

// const UpdatePlayerImageInProfile = ({ id }: any) => {
//   console.log("id to update", id);
//   const [showModal, setShowModal] = useState(false);
//   const [capturedImage, setCapturedImage] = useState<string | null>(null); // Store base64 string
//   const [compressedImage, setCompressedImage] = useState<File | null>(null); // Store compressed file
//   const webcamRef = useRef<Webcam>(null);

//   // Show modal
//   const handleShow = () => setShowModal(true);
//   // Hide modal
//   const handleClose = () => {
//     setShowModal(false);
//     setCapturedImage(null);
//     setCompressedImage(null);
//   };

//   // Capture image
//   const capture = React.useCallback(() => {
//     const imageSrc = webcamRef.current?.getScreenshot();
//     if (imageSrc) {
//       setCapturedImage(imageSrc); // Save the captured image base64 string
//     }
//   }, [webcamRef]);

//   // Compress the captured image
//   const compressImage = async (imageSrc: string) => {
//     try {
//       const imageFile = dataURItoFile(imageSrc, "captured-image.png"); // Convert base64 to File
//       const options = {
//         maxSizeMB: 0.2, // Max size 200KB
//         maxWidthOrHeight: 500, // Max image width or height
//         useWebWorker: true,
//       };
//       const compressedFile = await imageCompression(imageFile, options);
//       setCompressedImage(compressedFile); // Save the compressed image as a file
//     } catch (error) {
//       console.error("Image compression failed:", error);
//     }
//   };

//   // Convert Data URI to File
//   const dataURItoFile = (dataURI: string, fileName: string): File => {
//     const byteString = atob(dataURI.split(",")[1]);
//     const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
//     const ab = new ArrayBuffer(byteString.length);
//     const ia = new Uint8Array(ab);
//     for (let i = 0; i < byteString.length; i++) {
//       ia[i] = byteString.charCodeAt(i);
//     }
//     return new File([ab], fileName, { type: mimeString }); // Create a File from Blob
//   };

//   // Submit handler
//   const handleSubmit = async () => {
//     if (compressedImage) {
//       const imageDetails = {
//         name: compressedImage.name, // Get the file name
//         size: compressedImage.size, // Get the file size
//         type: compressedImage.type, // Get the file type
//       };
//       console.log("Image Details:", imageDetails);
//       const formData = new FormData();
//       formData.append("photo", compressedImage);
//       formData.append("studentId", id);
//       const response = await api.updatePatchData(
//         `/api/update/player/profileImage`,
//         formData
//       );
//       console.log("updating player profile image", response);
//     } else {
//       console.log("No image to submit.");
//     }
//   };

//   return (
//     <>
//       <Button variant="primary" onClick={handleShow}>
//         Update Image
//       </Button>

//       <Modal show={showModal} onHide={handleClose} centered size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Capture Player Image</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Row>
//             <Col sm={6}>
//               <Webcam
//                 audio={false}
//                 ref={webcamRef}
//                 screenshotFormat="image/png" // Ensure PNG format
//                 width="100%"
//                 className="mt-3"
//                 videoConstraints={{
//                   facingMode: "user",
//                 }}
//               />
//             </Col>
//             <Col sm={6}>
//               {capturedImage && (
//                 <div className="mt-3">
//                   <img src={capturedImage} alt="Captured" width="100%" />
//                 </div>
//               )}
//             </Col>
//           </Row>

//           <div className="mt-3">
//             <Button
//               variant="secondary"
//               // onClick={capture}
//               onClick={() => {
//                 capture();
//                 if (capturedImage) compressImage(capturedImage); // Ensure image is passed for compression
//               }}
//             >
//               Capture Image
//             </Button>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button
//             variant="primary"
//             onClick={handleSubmit}
//             // onClick={() => {
//             //   if (capturedImage) compressImage(capturedImage); // Ensure image is passed for compression
//             // }}
//             disabled={!capturedImage}
//           >
//             Update Image{" "}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* Submit Button */}
//       {/* <Button
//         variant="success"
//         onClick={handleSubmit}
//         disabled={!compressedImage}
//         className="mt-3"
//       >
//         Submit
//       </Button> */}
//     </>
//   );
// };

// export default UpdatePlayerImageInProfile;

// import React, { useState, useRef } from "react";
// import { Modal, Button, Row, Col } from "react-bootstrap";
// import Webcam from "react-webcam";
// import imageCompression from "browser-image-compression";
// import api from "../../../../utils/ApiMethod";

// const UpdatePlayerImageInProfile = ({ id }: any) => {
//   console.log("id to update", id);
//   const [showModal, setShowModal] = useState(false);
//   const [capturedImage, setCapturedImage] = useState<string | null>(null); // Store base64 string
//   const [compressedImage, setCompressedImage] = useState<File | null>(null); // Store compressed file
//   const webcamRef = useRef<Webcam>(null);
//   console.log("capturedImage", capturedImage);
//   console.log("compressedImage", compressedImage);
//   // Show modal
//   const handleShow = () => setShowModal(true);
//   // Hide modal
//   const handleClose = () => {
//     setShowModal(false);
//     setCapturedImage(null);
//     // setCompressedImage(null);
//   };

//   // Capture image
//   const capture = React.useCallback(() => {
//     const imageSrc = webcamRef.current?.getScreenshot();
//     if (imageSrc) {
//       setCapturedImage(imageSrc); // Save the captured image base64 string
//     }
//     if (capturedImage) compressImage(capturedImage);
//   }, [webcamRef]);

//   // Compress the captured image
//   const compressImage = async (imageSrc: string) => {
//     try {
//       const imageFile = dataURItoFile(imageSrc, "captured-image.png"); // Convert base64 to File
//       const options = {
//         maxSizeMB: 0.2, // Max size 200KB
//         maxWidthOrHeight: 500, // Max image width or height
//         useWebWorker: true,
//       };
//       const compressedFile = await imageCompression(imageFile, options);
//       setCompressedImage(compressedFile); // Save the compressed image as a file
//     } catch (error) {
//       console.error("Image compression failed:", error);
//     }
//   };

//   // Convert Data URI to File
//   const dataURItoFile = (dataURI: string, fileName: string): File => {
//     const byteString = atob(dataURI.split(",")[1]);
//     const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
//     const ab = new ArrayBuffer(byteString.length);
//     const ia = new Uint8Array(ab);
//     for (let i = 0; i < byteString.length; i++) {
//       ia[i] = byteString.charCodeAt(i);
//     }
//     return new File([ab], fileName, { type: mimeString }); // Create a File from Blob
//   };

//   // Submit handler
//   const handleSubmit = async () => {
//     if (compressedImage) {
//       const imageDetails = {
//         name: compressedImage.name, // Get the file name
//         size: compressedImage.size, // Get the file size
//         type: compressedImage.type, // Get the file type
//       };
//       console.log("Image Details:", imageDetails);
//       const formData = new FormData();
//       formData.append("photo", compressedImage);
//       formData.append("studentId", id);
//       const response = await api.updatePatchData(
//         `/api/update/player/profileImage`,
//         formData
//       );
//       console.log("updating player profile image", response);
//     } else {
//       console.log("No image to submit.");
//     }
//   };

//   return (
//     <>
//       <Button variant="primary" onClick={handleShow}>
//         Update Image
//       </Button>

//       <Modal show={showModal} onHide={handleClose} centered size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Capture Player Image</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Row>
//             <Col sm={6}>
//               <Webcam
//                 audio={false}
//                 ref={webcamRef}
//                 screenshotFormat="image/png" // Ensure PNG format
//                 width="100%"
//                 className="mt-3"
//                 videoConstraints={{
//                   facingMode: "user",
//                 }}
//               />
//             </Col>
//             <Col sm={6}>
//               {capturedImage && (
//                 <div className="mt-3">
//                   <img src={capturedImage} alt="Captured" width="100%" />
//                 </div>
//               )}
//             </Col>
//           </Row>

//           <div className="mt-3">
//             <Button
//               variant="secondary"
//               onClick={() => {
//                 capture();
//                 // if (capturedImage) compressImage(capturedImage); // Ensure image is passed for compression
//               }}
//             >
//               Capture Image
//             </Button>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button
//             variant="primary"
//             onClick={handleSubmit}
//             disabled={!compressedImage}
//           >
//             Update Image{" "}
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default UpdatePlayerImageInProfile;

import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal, Button, Row, Form, Col, Alert } from "react-bootstrap";

import Webcam from "react-webcam";
import imageCompression from "browser-image-compression";
import api from "../../../../utils/ApiMethod";

const UpdatePlayerImageInProfile = ({ id }: any) => {
  // console.log("id to update", id);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [capturedImage, setCapturedImage] = useState<string | null>(null); // Store base64 string
  const [compressedImage, setCompressedImage] = useState<File | null>(null); // Store compressed file
  const webcamRef = useRef<Webcam>(null);
  const [facingMode, setFacingMode] = useState<"user" | "environment">("user");

  const [errorImageMessage, setErrorImageMessage] = useState("");
  // Show modal
  const handleShow = () => setShowModal(true);
  // Hide modal
  const handleClose = () => {
    setShowModal(false);
    setCapturedImage(null);
    setCompressedImage(null); // Reset compressed image as well
  };

  // Capture image
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setCapturedImage(imageSrc); // Save the captured image base64 string
    }
  }, [webcamRef]);

  // Compress the captured image
  const compressImage = async (imageSrc: string) => {
    try {
      const imageFile = dataURItoFile(imageSrc, "captured-image.png"); // Convert base64 to File
      const options = {
        maxSizeMB: 0.2, // Max size 200KB
        maxWidthOrHeight: 500, // Max image width or height
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(imageFile, options);
      setCompressedImage(compressedFile); // Save the compressed image as a file
    } catch (error) {
      console.error("Image compression failed:", error);
    }
  };

  // Convert Data URI to File
  const dataURItoFile = (dataURI: string, fileName: string): File => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], fileName, { type: mimeString }); // Create a File from Blob
  };

  // Submit handler
  const handleSubmit = async () => {
    if (compressedImage) {
      const imageDetails = {
        name: compressedImage.name, // Get the file name
        size: compressedImage.size, // Get the file size
        type: compressedImage.type, // Get the file type
      };
      // console.log("Image Details:", imageDetails);
      const formData = new FormData();
      formData.append("photo", compressedImage);
      formData.append("studentId", id);
      const response = await api.updatePatchData(
        `/api/update/player/profileImage`,
        formData
      );
      if (response.status !== 200) {
        setErrorImageMessage(
          response?.message ||
            "Something wrong in updating player's profile image "
        );
      }
      if (response.status === 200) {
        window.location.reload();
      }
      // console.log("updating player profile image", response);
    } else {
      console.log("No image to submit.");
    }
  };

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        style={{ marginLeft: "5px", marginTop: "5px" }}
      >
        Update Image
      </Button>

      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Capture Player Image</Modal.Title>
        </Modal.Header>
        {errorImageMessage && <Alert>{errorImageMessage}</Alert>}
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <>
                <Form.Control
                  as="select"
                  value={selectedDeviceId}
                  onChange={(e) => setSelectedDeviceId(e.target.value)}
                >
                  {devices.map((device, key) => (
                    <option value={device.deviceId} key={key}>
                      {device.label || `Device ${key + 1}`}
                    </option>
                  ))}
                </Form.Control>
                <Button
                  variant="secondary"
                  className="mt-2"
                  onClick={() =>
                    setFacingMode((prev) =>
                      prev === "user" ? "environment" : "user"
                    )
                  }
                >
                  Switch to {facingMode === "user" ? "Back" : "Front"} Camera
                </Button>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/png" // Ensure PNG format
                  width="100%"
                  className="mt-3"
                  // videoConstraints={{
                  //   facingMode: "user",
                  // }}

                  videoConstraints={{
                    deviceId: selectedDeviceId || undefined,
                    facingMode,
                  }}
                />
              </>
            </Col>
            <Col sm={6}>
              {capturedImage && (
                <div className="mt-3">
                  <img src={capturedImage} alt="Captured" width="100%" />
                </div>
              )}
            </Col>
          </Row>

          <div className="mt-3">
            <Button
              style={capturedImage ? { display: "none" } : {}}
              variant="secondary"
              className="mr-3"
              onClick={() => {
                capture(); // Capture the image first
              }}
            >
              Capture Image
            </Button>
            {capturedImage && (
              <Button
                variant="secondary"
                style={compressedImage ? { display: "none" } : {}}
                onClick={() => compressImage(capturedImage)} // Compress after capturing
                className="ml-3"
              >
                Compress Image
              </Button>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {capturedImage && compressedImage && (
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!compressedImage}
            >
              Update Image
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePlayerImageInProfile;
