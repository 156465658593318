import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

import imgBase64 from "../../../assets/pdfBase64";

// Define the structures based on the data types you provided
interface gameData {
  cashRewards: string;
  game: string;
  rank: string;
}

interface selectedStudent {
  StudentMongooseID: string;
  cashRewardAccepted: string;
  played: boolean;
  studentName: string;
  uniqueStudentId: string;
}

interface IndividualData {
  studentId: string;
  Udise_Code: string;
  studentName: string;
  ageCategory: string;
  gameName: gameData[];
  fatherName:string;
  // Add other fields as per your individual data structure
}

interface TeamData {
  teamName: string;
  Block_Name_Code: string;
  sportName: string;
  ageCategory: string;
  selectedStudents: selectedStudent[];

  // Add other fields as per your team data structure
}

interface TableProps {
  columns: any[];
  data: IndividualData[];
  pageSize: number;
  sizePerPageList: number[];
  isSortable: boolean;
  pagination: boolean;
  isSearchable: boolean;
  tableClass: string;
  schoolName:string;
  Udise_Code: string;
}

const Table: React.FC<TableProps> = ({
  columns,
  schoolName,
  Udise_Code,
  data,
  pageSize,
  sizePerPageList,
  isSortable,
  pagination,
  isSearchable,
  tableClass,
}) => {
  const exportToPDF = () => {
    const doc = new jsPDF();
    const imageBase64 = imgBase64;
    const pdfWidth = doc.internal.pageSize.width;
    doc.addImage(imageBase64, 'JPEG',0, 0, pdfWidth, 20);

    // doc.text("School:- "+ schoolName + " Players" + ", " + "Udise Code:- "+  Udise_Code , 14, 40);
    doc.text(`School : -${schoolName}`, 14, 30); // Title for Individual Data
    doc.text(`UDISE Code :- ${Udise_Code}`, 18, 40); // Second line, positioned 10 points lower

    // Section 1: Individual Data
    // doc.text("", 14, 10); // Title for Individual Data
    // doc.text("Additional Info", 14, 20); // Second line, positioned 10 points lower
    // doc.text("More Details Here", 14, 30); // Third line, positioned another 10 points lower
    // // Define headers based on the structure of `data`
    const individualHeaders = [
      "SN.",
      "Player ID",
      "Father",
      "Name",
      "Height (cm)",
      "Weight (kg)",
      "30 Mtr sprint (sec)",
      "6X10 Mtr shuttle run (sec)",
      "Vertical Jump (m)",
      "Standing Broad jump (cm)",
      "Football Throw (m)",
      "800 mts Run (mm: ss:sss)",
    ]; // Update headers based on your structure
    const individualRows = data.flatMap((item, index) => {
      // Create an array to hold rows for the individual student
      const rows = item.gameName.map((game) => {
        return [
          index + 1, // Serial number
          item?.studentId,
          item?.fatherName,
          item?.studentName,
          "____",
          "____",
          "____",
          "____",
          "____",
          "____",
          "____",
          "____",
        ];
      });
      return rows; // Return all rows for the current student
    });

    (doc as any).autoTable({
      head: [individualHeaders],
      body: individualRows,
      startY: 50,
    });
    doc.save("RawBatteryTest.pdf");
  };
  return (
    <div className="data-table">
      {/* Render your table here based on props like `columns`, `data`, etc. */}
      <button className="btn btn-primary me-1" onClick={exportToPDF}>
        <i className="uil uil-file-alt me-1" /> Export as PDF
      </button>
    </div>
  );
};

export default Table;
