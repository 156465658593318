import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
import api from "../../../../utils/ApiMethod";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { logoutUser } from "../../../../redux/actions";
import { ADMIN_LOGIN_BASE_URL } from "../../../../apiservices/apiService";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import UpdatePlayerImageInProfile from "./UpdatePlayerImageInProfile";
import UpdatePlayerAadharImage from "./UpdatePlayerAadharImage";

interface InputField {
  value: string;
  isValid: boolean;
}

interface Game {
  game?: string;
  rank?: string; // or whatever type `rank` should be
}

interface Document {
  fileName: string;
  fileUrl: string;
  verified?: boolean;
  checkedBy?: string | null;
  uploadDate?: string; // Add more fields as necessary
}

interface AadharDocument {
  fileName: string;
  fileUrlOfAadharFront: string;
  fileUrlOfAadharBack: string;
  verified?: boolean;
  checkedBy?: string | null;
  uploadDate?: string; // Add more fields as necessary
}
// ---------------- one two three
interface StudentForm {
  studentName: string;
  gender: "Female" | "Male";
  aadharNumber: string;
  fatherName: string;
  motherName: string;
  dob: string;
  caldob: string;
  ageCategory:
    | "U-14 (Boys)"
    | "U-14 (Girls)"
    | "U-16 (Boys)"
    | "U-16 (Girls)"
    | "";
  mobileNumber: string;
  email: string;
  gameName: Game[]; // Updated to an array of Game objects
  document?: Document[];
  photo?: Document[];
}

// ==================

interface StudentFormData {
  Udise_Code: string;
  studentId: string;
  selectedInTeam: string;
  studentName: InputField;
  gender: InputField;
  // gender: string;
  aadharNumber: InputField;
  fatherName: InputField;
  motherName: InputField;
  dob: InputField;
  caldob: InputField;
  ageCategory: "U-14 (Boys)" | "U-14 (Girls)" | "U-16 (Boys)" | "U-16 (Girls)";
  mobileNumber: InputField;
  email: InputField;
  accountNumber: string;
  level: string[];
  // gameName: string[];
  gameName: []; // Updated to an array of Game objects
  height: InputField;
  weight: InputField;
  thirtyMFlingStarts: InputField;
  standingBroadJump: InputField;
  shuttleRun10MX6: InputField;
  verticalJump: InputField;
  footballBallThrow5No: InputField;
  eightHundredMetersRun: InputField;
  accountHolderName: string;
  ifscCode: string;
  bankName: string;
  bankBranchName: string;
  updatable?: Boolean;
  _id?: string;
  attachments: File[];
  document?: Document[];
  photo?: Document[];
  liveAadharCapturedImageData?: AadharDocument[];
}

// const gameOptions = {
//   "U-14": [
//     "Football",
//     "Kabaddi",
//     "Cycling",
//     "Longjump (Athletics)",
//     "cricketBallThrow (Athletics)",
//     "60m (run) (Athletics)",
//     "600m (run) (Athletics)",
//   ],
//   "U-16": [
//     "Football",
//     "Kabaddi",
//     "Cycling",
//     "Longjump (Athletics)",
//     "cricketBallThrow (Athletics)",
//     "100m (run) (Athletics)",
//     "800m (run) (Athletics)",
//   ],
// };

// const gameOptions = {
//   "U-14": [
//     "Football",
//     "Kabaddi",
//     "Cycling",
//     "Longjump (Athletics)",
//     "cricketBallThrow (Athletics)",
//     "60m (run) (Athletics)",
//     "600m (run) (Athletics)",
//   ],
//   "U-16": [
//     "Football",
//     "Kabaddi",
//     "Cycling",
//     "Longjump (Athletics)",
//     "cricketBallThrow (Athletics)",
//     "100m (run) (Athletics)",
//     "800m (run) (Athletics)",
//   ],
// };

const gameOptions = {
  "U-14-boys": [
    "Football",
    "Kabaddi",
    // "Cycling",
    "Longjump (Athletics)",
    "cricketBallThrow (Athletics)",
    // "footballThrow (Athletics)",
    "Cycling (5km)",
    "60m (run) (Athletics)",
    "600m (run) (Athletics)",
  ],
  "U-14-girls": [
    "Kabaddi",
    // "Cycling",
    "Cycling (3km)",
    "Longjump (Athletics)",
    "cricketBallThrow (Athletics)",
    // "footballThrow (Athletics)",
    "60m (run) (Athletics)",
    "600m (run) (Athletics)",
  ],
  "U-16-boys": [
    "Football",
    "Kabaddi",
    "Volleyball",
    // "Cycling",
    "Cycling (5km)",
    "Longjump (Athletics)",
    "cricketBallThrow (Athletics)",
    // "footballThrow (Athletics)",
    "100m (run) (Athletics)",
    "800m (run) (Athletics)",
  ],
  "U-16-girls": [
    "Kabaddi",
    // "Cycling",
    "Cycling (3km)",
    "Longjump (Athletics)",
    "cricketBallThrow (Athletics)",
    // "footballThrow (Athletics)",
    "100m (run) (Athletics)",
    "800m (run) (Athletics)",
  ],
};

const FilePreview: React.FC<{
  files: File[];
  removeFile: (index: number) => void;
}> = ({ files, removeFile }) => (
  <div className="file-preview mt-3">
    {files.length > 0
      ? files?.map((file, index) => (
          <Card key={index} className="mb-2">
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div>
                <i className="bi bi-paperclip me-2"></i>
                {file.name} ({(file.size / 1024).toFixed(2)} KB)
              </div>
              <Button
                variant="danger"
                size="sm"
                onClick={() => removeFile(index)}
              >
                Remove
              </Button>
            </Card.Body>
          </Card>
        ))
      : null}
  </div>
);

const ProfileStudentRegistrationForm: React.FC = () => {
  const [formData, setFormData] = useState<StudentFormData>({
    Udise_Code: "",
    studentId: "",
    selectedInTeam: "",
    studentName: { value: "", isValid: true },
    gender: { value: "Female", isValid: true },
    aadharNumber: { value: "", isValid: true },
    fatherName: { value: "", isValid: true },
    motherName: { value: "", isValid: true },
    dob: { value: "", isValid: true },
    caldob: { value: "", isValid: true },
    ageCategory: "U-14 (Boys)",
    mobileNumber: { value: "", isValid: true },
    email: { value: "", isValid: true },
    accountNumber: "",
    level: [],
    gameName: [],
    height: { value: "", isValid: true },
    weight: { value: "", isValid: true },
    thirtyMFlingStarts: { value: "", isValid: true },
    standingBroadJump: { value: "", isValid: true },
    shuttleRun10MX6: { value: "", isValid: true },
    verticalJump: { value: "", isValid: true },
    footballBallThrow5No: { value: "", isValid: true },
    eightHundredMetersRun: { value: "", isValid: true },
    accountHolderName: "",
    ifscCode: "",
    bankName: "",
    bankBranchName: "",
    attachments: [],
    document: [],
    photo: [],
    liveAadharCapturedImageData: [],
  });
  // console.log("formData-check1", formData);
  const [isError, setIsError] = useState<boolean>(false);

  const [finalSubmit, setFinalSubmit] = useState<boolean>(false);
  const [saveButton, setSaveButton] = useState<boolean>(false);

  const [isResponse, setIsResponse] = useState("");

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const { studentId } = useParams();
  const dobRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  //  for changing the dob

  // -------------------------------------------------

  // console.log(formData.mobileNumber, "MOBILE");

  let profileId = user?._id ?? user?.user?._id ?? "";
  const Key_Key = user?.moniterd_ttl ?? user?.user?.moniterd_ttl ?? "";

  const formatDateString = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const fetchStudentProfiles = async () => {
    try {
      const response = await api.fetchDataAuth(
        `/student-profile/${studentId}/${profileId}/${user?.Udise_Code}/${Key_Key}`
      );
      // console.log("response?.data", response?.data);

      const fetchedData = response?.data?.data;

      setFormData({
        Udise_Code: fetchedData.Udise_Code,
        studentId: fetchedData.studentId,
        selectedInTeam: fetchedData.selectedInTeam,
        studentName: { value: fetchedData.studentName || "", isValid: true },
        gender: { value: fetchedData.gender || "", isValid: true },
        aadharNumber: { value: fetchedData.aadharNumber || "", isValid: true },
        fatherName: { value: fetchedData.fatherName || "", isValid: true },
        motherName: { value: fetchedData.motherName || "", isValid: true },
        updatable: fetchedData.updatable,
        _id: fetchedData._id,

        dob: {
          value: fetchedData.dob ? formatDateString(fetchedData.dob) : "",
          isValid: true,
        },
        caldob: { value: fetchedData.caldob || "", isValid: true },
        ageCategory: fetchedData.ageCategory,
        mobileNumber: { value: fetchedData.mobileNumber || "", isValid: true },
        email: { value: fetchedData.email || "", isValid: true },
        accountNumber: fetchedData.accountNumber,
        level: fetchedData.level,
        gameName: fetchedData.gameName,
        document: fetchedData.document,
        photo: fetchedData.photo,
        height: { value: fetchedData.height || "", isValid: true },
        weight: { value: fetchedData.weight || "", isValid: true },
        thirtyMFlingStarts: {
          value: fetchedData.thirtyMFlingStarts || "",
          isValid: true,
        },
        standingBroadJump: {
          value: fetchedData.standingBroadJump || "",
          isValid: true,
        },
        shuttleRun10MX6: {
          value: fetchedData.shuttleRun10MX6 || "",
          isValid: true,
        },
        verticalJump: { value: fetchedData.verticalJump || "", isValid: true },
        footballBallThrow5No: {
          value: fetchedData.footballBallThrow5No || "",
          isValid: true,
        },
        eightHundredMetersRun: {
          value: fetchedData.eightHundredMetersRun || "",
          isValid: true,
        },
        accountHolderName: fetchedData.accountHolderName,
        ifscCode: fetchedData.ifscCode,
        bankName: fetchedData.bankName,
        bankBranchName: fetchedData.bankBranchName,
        attachments: [],
        liveAadharCapturedImageData: fetchedData?.liveAadharCapturedImageData,
      });
    } catch (error) {
      console.error("Error fetching student profile:", error);
    }
  };
  // console.log('student  id', formData?._id, 'jvhui', formData.studentId)

  useEffect(() => {
    fetchStudentProfiles();
  }, [studentId]);

  // console.log(formData.studentId, "STDENT ID");

  // const handleChange = (e: React.ChangeEvent<any>) => {
  //   const { name, value } = e.target;
  //   const validNumberRegex = /^\d*\.?\d{0,2}$/;
  //   const isValid = validNumberRegex.test(value);

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: { value, isValid },
  //   }));
  // };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    let validRegex: RegExp;

    // Define regex and validation logic based on the field name
    switch (name) {
      case "studentName":
        validRegex = /^[A-Za-z][A-Za-z.\s]*$/;
        break;
      case "fatherName":
        validRegex = /^[A-Za-z][A-Za-z.\s]*$/;
        break;
      case "motherName":
        validRegex = /^[A-Za-z][A-Za-z.\s]*$/;
        break;
      case "aadharNumber":
        validRegex = /^\d{12}$/;
        break;
      case "mobileNumber":
        validRegex = /^\d{10}$/;
        break;
      case "email":
        validRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        break;
      case "height":
        // validRegex = /^\d{0,3}$/; // Allow up to 3 digits for height
        validRegex = /^(?!0$)\d{1,3}$/; // Allows 1 to 3 digits but excludes single "0"
        // validRegex = /^(?:1[0-9]{1,2}|[2-9][0-9]?)$/; // add for more validation
        break;
      case "eightHundredMetersRun":
        // validRegex = /^([0-5]?[0-9]):([0-5]?[0-9]):([0-9]{1,3})$/; // Time format MM:SS:SSS for eightHundredMetersRun
        validRegex =
          /^(?!0:00:0{1,3}$)(?!00:00:000$)(?=.*[1-9])([0-5]?[0-9]):([0-5]?[0-9]):([0-9]{1,3})$/;
        break;
      default:
        // validRegex = /^\d{0,3}(\.\d{0,2})?$/; // Default regex for other fields (up to 3 digits and up to 2 decimal places)
        validRegex = /^(?!0$)\d{1,3}(\.\d{0,2})?$/; // Up to 3 digits and 2 decimal places, excludes single "0"
    }

    let formattedValue = value;
    if (name === "eightHundredMetersRun") {
      formattedValue = formatTime(value); // Format time for eightHundredMetersRun field
    }

    const isValid = validRegex.test(formattedValue);

    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: formattedValue, isValid },
    }));
  };

  useEffect(() => {
    if (formData.dob) {
      const birthDate = new Date(formData.dob.value);
      const currentDate = new Date("2024-12-31");

      // Check if the birth date is in the future
      if (birthDate > currentDate) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: "Date of Birth cannot be in the future. Please enter a valid date of birth.",
        }));
        setFormData((prevData: any) => ({
          ...prevData,
          dob: "",
          caldob: "",
          ageCategory: "",
          // gameName: [],
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: "",
        }));
      }

      let age = currentDate.getFullYear() - birthDate.getFullYear();
      let month = currentDate.getMonth() - birthDate.getMonth();
      let day = currentDate.getDate() - birthDate.getDate();

      // Adjust for months and days
      if (month < 0 || (month === 0 && day < 0)) {
        age--;
        month += 12;
      }

      if (day < 0) {
        const lastMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          0
        );
        day += lastMonth.getDate();
        month--;
      }

      const caldobText = `${age} years ${month} months ${day} days`;

      console.log("caldobText------", caldobText)

      let ageCategory: StudentForm["ageCategory"] = "";

      if (age === 0 && month === 0 && day === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: "Age cannot be 0. Please enter a valid date of birth.",
        }));
        setFormData((prevData: any) => ({
          ...prevData,
          dob: "",
          caldob: "",
          ageCategory: "",
          // gameName: [],
        }));
        return;
      }

      // U-14 Category: 0 to 14 years 0 months 0 days inclusive
      if (age < 14 || (age === 14 && month === 0 && day === 0)) {
        ageCategory =
          formData.gender.value === "Female" ? "U-14 (Girls)" : "U-14 (Boys)";
      }
      // U-16 Category: 14 years 0 months 1 day to 16 years 0 months 0 days inclusive
      else if (
        (age === 14 && (month > 0 || (month === 0 && day > 0))) ||
        age === 15 ||
        (age === 16 && month === 0 && day === 0)
      ) {
        ageCategory =
          formData.gender.value === "Female" ? "U-16 (Girls)" : "U-16 (Boys)";
      }
      // Not Eligible: 16 years 0 months 1 day upwards
      else if (
        age > 16 ||
        (age === 16 && (month > 0 || (month === 0 && day > 0)))
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: `You are not allowed to register due to age restrictions. Calculated age: ${caldobText}. Please re-enter the date of birth.`,
        }));
        setFormData((prevData: any) => ({
          ...prevData,
          dob: "",
          caldob: "",
          ageCategory: "",
          // gameName: [],
        }));
        return;
      }

      // setFormData((prevData: any) => ({
      //   ...prevData,
      //   caldob: caldobText,
      //   ageCategory: ageCategory,
      //   // gameName: [],
      // }));

      setFormData((prevData: any) => ({
        ...prevData,
        caldob: { ...prevData.caldob, value: caldobText }, // Update caldob's value while retaining validity
        ageCategory: ageCategory, // Directly update ageCategory
      }));
    }
  }, [formData.dob.value, formData.gender.value]);

  const formatTime = (value: string) => {
    const cleaned = value.replace(/\D+/g, ""); // Remove all non-numeric characters
    const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,3})$/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join(":");
    }
    return value;
  };

  // console.log(formData, "on handle change");

  const getInputStyle = (isValid: boolean) => ({
    borderColor: isValid ? "green" : "red",
  });

  // const handleGameChange = (selectedOptions: any) => {
  //   const selectedGames = selectedOptions
  //     ? selectedOptions.map((option: any) => option.value)
  //     : [];
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     gameName: selectedGames,
  //   }));
  // };

  // const handleGameChange = (selectedOptions: any) => {
  //   const updatedGames = selectedOptions.map((option: any) => ({
  //     game: option.value, // Map the selected option to the new structure
  //     rank: "", // Optionally add rank or other properties if needed
  //   }));

  //   setFormData({
  //     ...formData,
  //     gameName: updatedGames, // Update gameName with the new structure
  //   });
  // };

  const handleGameChange = (selectedOptions: any) => {
    // Map selected options to the new structure
    const updatedGames = selectedOptions
      ? selectedOptions?.map((option: any) => ({
          game: option.value, // Map option to the 'game' field
          rank: "", // Optional field for 'rank', initialize as empty string
        }))
      : [];

    // Update formData with the new structure for gameName
    setFormData((prevData) => ({
      ...prevData,
      gameName: updatedGames, // Set updated gameName structure
    }));
  };

  // const validateField = (name: string, value: any) => {
  //   let errorMsg = "";
  //   switch (name) {
  //     case "studentName":
  //       if (!value) errorMsg = "Player's Full Name is required.";
  //       // else if (!/^[^\d\s<>][\w\s]*$/.test(value))
  //       else if (!/^[A-Za-z][A-Za-z.\s]*$/.test(value))
  //         errorMsg = "Player's Full Name is not valid.";
  //       break;
  //     case "aadharNumber":
  //       if (!value) errorMsg = "Aadhar Number is required.";
  //       else if (!/^\d{12}$/.test(value))
  //         errorMsg = "Aadhar Number must be 12 digits.";
  //       break;
  //     case "fatherName":
  //       if (!value) errorMsg = "Father's Full Name is required.";
  //       // else if (!/^[^\d\s<>][\w\s]*$/.test(value))
  //       else if (!/^[A-Za-z][A-Za-z.\s]*$/.test(value))
  //         errorMsg = "Father's Full Name is not valid.";
  //       break;
  //     case "motherName":
  //       if (!value) errorMsg = "Mother's Full Name is required.";
  //       // else if (!/^[^\d\s<>][\w\s]*$/.test(value))
  //       else if (!/^[A-Za-z][A-Za-z.\s]*$/.test(value))
  //         errorMsg = "Mother's Full Name is not valid.";
  //       break;
  //     case "dob":
  //       if (!value) errorMsg = "Date of Birth is required.";
  //       break;
  //     case "mobileNumber":
  //       if (!value) errorMsg = "Mobile Number is required.";
  //       else if (!/^\d{10}$/.test(value))
  //         errorMsg = "Mobile Number must be 10 digits.";
  //       break;
  //     case "email":
  //       if (value && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value))
  //         errorMsg = "Invalid email address.";
  //       break;
  //     case "gameName":
  //       if (value.length === 0)
  //         errorMsg = "At least one game must be selected.";
  //       break;
  //     default:
  //       break;
  //   }

  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: errorMsg,
  //   }));
  // };

  //   const getAvailableGames = () => {
  //     const ageGroup = formData?.ageCategory?.startsWith("U-14")
  //       ? "U-14"
  //       : "U-16";
  //     const allGames = gameOptions[ageGroup];

  //     // const selectedGames = formData?.gameName;
  //     // const athleticsGamesSelected = selectedGames?.filter((game) =>
  //     //   game?.includes("(Athletics)")
  //     // ).length;

  //     const defaultRank = "";

  //     // Convert formData.gameName (array of strings) to selectedGames (array of Game objects)
  //     const selectedGames: Game[] = formData?.gameName.map((gameName) => ({
  //       game: gameName,
  //       rank: defaultRank, // Assign a default rank or modify this based on your logic
  //     }));

  //    const athleticsGamesSelected = selectedGames?.filter((gameObj) =>
  //   typeof gameObj?.game === "string" && gameObj.game.includes("(Athletics)")
  // ).length;

  //     // const isOtherGameSelected = selectedGames.some(
  //     //   (game) => !game.includes("(Athletics)")
  //     // );

  //     // Check if any game is not an athletics game

  //     // Check if any other game is selected
  //     const isOtherGameSelected = selectedGames.some(
  //       (gameObj) =>  typeof gameObj?.game === "string" && !gameObj?.game?.includes("(Athletics)") // Use the 'game' property
  //     );

  //     return allGames.map((game) => ({
  //       value: game,
  //       label: game,
  //       isDisabled:
  //         isOtherGameSelected ||
  //         (athleticsGamesSelected >= 2 && game.includes("(Athletics)")) ||
  //         (athleticsGamesSelected > 0 && !game.includes("(Athletics)")),
  //     }));
  //   };

  const getAvailableGames = () => {
    // Determine the age group (U-14 or U-16)
    // const ageGroup = formData?.ageCategory?.startsWith("U-14") ? "U-14" : "U-16";
    const ageGroup = formData?.ageCategory?.startsWith("U-14 (Boys)")
      ? "U-14-boys"
      : formData.ageCategory.startsWith("U-14 (Girls)")
      ? "U-14-girls"
      : formData.ageCategory.startsWith("U-16 (Boys)")
      ? "U-16-boys"
      : "U-16-girls";
    const allGames = gameOptions[ageGroup];

    // Selected games from formData (array of Game objects)
    const selectedGames: Game[] = formData?.gameName || [];

    // Count how many athletics games are selected
    const athleticsGamesSelected = selectedGames?.filter(
      (gameObj) =>
        typeof gameObj?.game === "string" &&
        gameObj?.game.includes("(Athletics)")
    ).length;

    // Check if any other (non-athletics) game is selected
    const isOtherGameSelected = selectedGames.some(
      (gameObj) =>
        typeof gameObj?.game === "string" &&
        !gameObj?.game.includes("(Athletics)")
    );

    // Map available games with appropriate disabling conditions
    return allGames?.map((game) => ({
      value: game,
      label: game,
      isDisabled:
        isOtherGameSelected ||
        (athleticsGamesSelected >= 1 && game.includes("(Athletics)")) ||
        (athleticsGamesSelected > 0 && !game.includes("(Athletics)")),
    }));
  };

  const availableGames = getAvailableGames();

  // console.log("availableGames-------", availableGames, formData.gameName);

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      maxHeight: 200,
      overflowY: "auto",
    }),
  };

  useEffect(() => {
    const dobInput = dobRef.current;
    const handleFocus = () => {
      if (
        dobInput instanceof HTMLInputElement &&
        typeof dobInput.focus === "function"
      ) {
        dobInput.focus();
      }
    };

    if (dobInput) {
      dobInput.addEventListener("focus", handleFocus);
    }

    return () => {
      if (dobInput) {
        dobInput.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  // save function deribed from final submittion need to be committed
  const updateStudentProfile = async (studentId: string, action: string) => {
    setIsError(false);
    setSaveButton(true);

    const invalidFields = Object.entries(formData).filter(
      ([key, value]) => typeof value === "object" && !value.isValid
    );

    if (!formData.studentName.isValid) {
      setSaveButton(false);
      return alert("Invalid input for Player Name");
    }

    if (!formData.aadharNumber.isValid) {
      setSaveButton(false);
      return alert("Invalid input for Aadhar Number");
    }

    if (!formData.fatherName.isValid) {
      setSaveButton(false);
      return alert("Invalid input for Player's Father Name");
    }

    if (!formData.motherName.isValid) {
      setSaveButton(false);
      return alert("Invalid input for Player's Mother Name");
    }

    if (!formData.mobileNumber.isValid) {
      setSaveButton(false);

      return alert("Invalid input for Parent/Guardian Mobile Number");
    }

    if (!formData.email.isValid) {
      setSaveButton(false);
      return alert("Invalid input for Email");
    }
    const formDataToSend = new FormData();
    // Append each piece of state data to the FormData object
    formDataToSend.append("studentId", formData.studentId);
    formDataToSend.append("selectedInTeam", formData.selectedInTeam);
    formDataToSend.append("studentName", formData.studentName.value);
    formDataToSend.append("gender", formData.gender.value);
    formDataToSend.append("aadharNumber", formData.aadharNumber.value);
    formDataToSend.append("fatherName", formData.fatherName.value);
    formDataToSend.append("motherName", formData.motherName.value);
    formDataToSend.append("dob", formData.dob.value);
    formDataToSend.append("caldob", formData.caldob.value);
    formDataToSend.append("ageCategory", formData.ageCategory);
    formDataToSend.append("mobileNumber", formData.mobileNumber.value);
    formDataToSend.append("email", formData.email.value);
    formDataToSend.append("accountNumber", formData.accountNumber);
    formData.level.forEach((level, index) => {
      formDataToSend.append(`level[${index}]`, level);
    });

    formData.gameName.forEach((gameObj: Game, index: number) => {
      const game = gameObj.game || ""; // Get the game name
      const rank = gameObj.rank || ""; // Get the rank or default to an empty string

      // Append the game name and rank to the FormData
      formDataToSend.append(`gameName[${index}][game]`, game);
      formDataToSend.append(`gameName[${index}][rank]`, rank);
    });

    // formDataToSend.append("height", formData.height.value);
    // formDataToSend.append("weight", formData.weight.value);
    // formDataToSend.append(
    //   "thirtyMFlingStarts",
    //   formData.thirtyMFlingStarts.value
    // );
    // formDataToSend.append(
    //   "standingBroadJump",
    //   formData.standingBroadJump.value
    // );
    // formDataToSend.append("shuttleRun10MX6", formData.shuttleRun10MX6.value);
    // formDataToSend.append("verticalJump", formData.verticalJump.value);
    // formDataToSend.append(
    //   "footballBallThrow5No",
    //   formData.footballBallThrow5No.value
    // );
    // formDataToSend.append(
    //   "eightHundredMetersRun",
    //   formData.eightHundredMetersRun.value
    // );
    // if (
    //   formData.shuttleRun10MX6.value === "" ||
    //   formData.thirtyMFlingStarts.value === "" ||
    //   formData.verticalJump.value === "" ||
    //   formData.standingBroadJump.value === "" ||
    //   formData.weight.value === "" ||
    //   formData.height.value === "" ||
    //   formData.eightHundredMetersRun.value === ""
    // ) {
    //   setIsError(true);
    //   setFinalSubmit(false);
    //   let errorMessage =
    //     "Please enter relevant data in each of the fields above";
    //   setIsResponse(errorMessage);
    //   return;
    // }
    formDataToSend.append("accountHolderName", formData.accountHolderName);
    formDataToSend.append("ifscCode", formData.ifscCode);
    formDataToSend.append("bankName", formData.bankName);
    formDataToSend.append("bankBranchName", formData.bankBranchName);
    formDataToSend.append("Udise_Code", formData.Udise_Code);
    // console.log(formDataToSend, "PAYLOAD");

    if (formData.attachments[0]) {
      formDataToSend.append(
        "attachments",
        formData.attachments[0],
        formData.attachments[0].name
      );
    }

    // Confirmation alert before making the API call
    // const isConfirmed = window.confirm(
    //   "Save"
    // );
    // if (!isConfirmed) {
    //   setSaveButton(false);
    //   return;
    // }

    try {
      const response = await api.updatePatchData(
        `/update/student/register/${profileId}/${studentId}/${action}/${Key_Key}`,
        formDataToSend
      );
      if (response.status === 440) {
        setSaveButton(false);
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
      }
      if (response.status === 400) {
        setSaveButton(false);
        setIsError(true);
        let errorMessage = await response.message;
        setIsResponse(errorMessage);
      }
      if (!response.status === true) {
        // console.log("response--", await response.json())
        let errorMsg = await response.json();
        setSaveButton(false);
        setIsResponse(errorMsg.message);
        // console.log("response from update user---",response)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (response.status === 200 || response.status === 201) {
        alert("Your Record Submitted Successfully");
        setSaveButton(false);
        // window.location.reload();
        fetchStudentProfiles();
      }
      setIsResponse(response.message);
    } catch (error) {
      console.error("Error updating student profile:", error);
      setIsError(true);
      setSaveButton(false);
    }
  };

  // final summit function need to be uncommitted
  // const updateStudentProfile = async (studentId: string, action: string) => {
  //   setIsError(false);
  //   setFinalSubmit(true);
  //   setSaveButton(true)

  //   // if (5 == 5) {
  //   //   alert(
  //   //     "\n The final submission is closed until 31st January. Player registration can be done until that date. \n \n \n अंतिम सबमिशन 31 जनवरी तक बंद है। खिलाड़ी पंजीकरण उसी तारीख तक किया जा सकता है।"
  //   //   );
  //   //   return;
  //   // }

  //   const invalidFields = Object.entries(formData).filter(
  //     ([key, value]) => typeof value === "object" && !value.isValid
  //   );
  //   // console.log(invalidFields, "INPUT");

  //   // if(!formData.height.isValid ) {
  //   //   setFinalSubmit(false);
  //   //   return alert("Invalid input for Height (in cm).")
  //   // }

  //   if (!formData.studentName.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Player Name");
  //   }

  //   if (!formData.aadharNumber.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Aadhar Number");
  //   }

  //   if (!formData.fatherName.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Player's Father Name");
  //   }

  //   if (!formData.motherName.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Player's Mother Name");
  //   }

  //   if (!formData.mobileNumber.isValid) {
  //     setFinalSubmit(false);

  //     return alert("Invalid input for Parent/Guardian Mobile Number");
  //   }

  //   if (!formData.email.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Email");
  //   }

  //   if (!formData.height.isValid || formData.height.value === "0") {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Height (in cm).");
  //   }

  //   if (!formData.weight.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Weight (in Kg).");
  //   }

  //   if (!formData.verticalJump.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Vertical Jump (in cm).");
  //   }
  //   if (!formData.thirtyMFlingStarts.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for 30 Mtr sprint(in sec.");
  //   }
  //   if (!formData.footballBallThrow5No.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Football Throw ( in meter).");
  //   }
  //   if (!formData.shuttleRun10MX6.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for 6X10 Mtr shuttle run (in second).");
  //   }
  //   if (!formData.standingBroadJump.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Standing Broad jump(in meter).");
  //   }
  //   if (!formData.eightHundredMetersRun.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for 800 mts Run ( in mm:ss:msms).");
  //   }

  //   // if (invalidFields.length > 0) {
  //   //   setIsError(true);
  //   //   return;
  //   // }

  //   // const payload = {
  //   //   studentId: formData.studentId,
  //   //   selectedInTeam: formData.selectedInTeam,
  //   //   studentName: formData.studentName.value,
  //   //   gender: formData.gender.value,
  //   //   aadharNumber: formData.aadharNumber.value,
  //   //   fatherName: formData.fatherName.value,
  //   //   motherName: formData.motherName.value,
  //   //   dob: formData.dob.value,
  //   //   caldob: formData.caldob.value,
  //   //   ageCategory: formData.ageCategory,
  //   //   mobileNumer: formData.mobileNumber.value,
  //   //   email: formData.email.value,
  //   //   accountNumber: formData.accountNumber,
  //   //   level: formData.level,
  //   //   gameName: formData.gameName,
  //   //   height: formData.height.value,
  //   //   weight: formData.weight.value,
  //   //   thirtyMFlingStarts: formData.thirtyMFlingStarts.value,
  //   //   standingBroadJump: formData.standingBroadJump.value,
  //   //   shuttleRun10MX6: formData.shuttleRun10MX6.value,
  //   //   verticalJump: formData.verticalJump.value,
  //   //   footballBallThrow5No: formData.footballBallThrow5No.value,
  //   //   eightHundredMetersRun: formData.eightHundredMetersRun.value,
  //   //   accountHolderName: formData.accountHolderName,
  //   //   ifscCode: formData.ifscCode,
  //   //   bankName: formData.bankName,
  //   //   bankBranchName: formData.bankBranchName,
  //   //   Udise_Code: formData.Udise_Code,
  //   // };

  //   const formDataToSend = new FormData();

  //   // Append each piece of state data to the FormData object
  //   formDataToSend.append("studentId", formData.studentId);
  //   formDataToSend.append("selectedInTeam", formData.selectedInTeam);
  //   formDataToSend.append("studentName", formData.studentName.value);
  //   formDataToSend.append("gender", formData.gender.value);
  //   formDataToSend.append("aadharNumber", formData.aadharNumber.value);
  //   formDataToSend.append("fatherName", formData.fatherName.value);
  //   formDataToSend.append("motherName", formData.motherName.value);
  //   formDataToSend.append("dob", formData.dob.value);
  //   formDataToSend.append("caldob", formData.caldob.value);
  //   formDataToSend.append("ageCategory", formData.ageCategory);
  //   formDataToSend.append("mobileNumber", formData.mobileNumber.value);
  //   formDataToSend.append("email", formData.email.value);
  //   formDataToSend.append("accountNumber", formData.accountNumber);
  //   formData.level.forEach((level, index) => {
  //     formDataToSend.append(`level[${index}]`, level);
  //   });

  //   // formData.gameName.forEach((game, index) => {
  //   //   formDataToSend.append(`gameName[${index}]`, game);
  //   // });
  //   // console.log("formData.gameName--------------", formData.gameName);

  //   // formData.gameName.forEach((game, index) => {
  //   //   const rank = ""; // You may need to include rank if it's part of the schema
  //   //   formDataToSend.append(`gameName[${index}][game]`, game);
  //   //   formDataToSend.append(`gameName[${index}][rank]`, rank); // If rank is relevant
  //   // });

  //   formData.gameName.forEach((gameObj: Game, index: number) => {
  //     const game = gameObj.game || ""; // Get the game name
  //     const rank = gameObj.rank || ""; // Get the rank or default to an empty string

  //     // Append the game name and rank to the FormData
  //     formDataToSend.append(`gameName[${index}][game]`, game);
  //     formDataToSend.append(`gameName[${index}][rank]`, rank);
  //   });

  //   formDataToSend.append("height", formData.height.value);
  //   formDataToSend.append("weight", formData.weight.value);
  //   formDataToSend.append(
  //     "thirtyMFlingStarts",
  //     formData.thirtyMFlingStarts.value
  //   );
  //   formDataToSend.append(
  //     "standingBroadJump",
  //     formData.standingBroadJump.value
  //   );
  //   formDataToSend.append("shuttleRun10MX6", formData.shuttleRun10MX6.value);
  //   formDataToSend.append("verticalJump", formData.verticalJump.value);
  //   formDataToSend.append(
  //     "footballBallThrow5No",
  //     formData.footballBallThrow5No.value
  //   );
  //   formDataToSend.append(
  //     "eightHundredMetersRun",
  //     formData.eightHundredMetersRun.value
  //   );
  //   if (
  //     formData.shuttleRun10MX6.value === "" ||
  //     formData.thirtyMFlingStarts.value === "" ||
  //     formData.verticalJump.value === "" ||
  //     formData.standingBroadJump.value === "" ||
  //     formData.weight.value === "" ||
  //     formData.height.value === "" ||
  //     formData.eightHundredMetersRun.value === ""
  //   ) {
  //     setIsError(true);
  //     setFinalSubmit(false);
  //     let errorMessage =
  //       "Please enter relevant data in each of the fields above";
  //     setIsResponse(errorMessage);
  //     return;
  //   }
  //   formDataToSend.append("accountHolderName", formData.accountHolderName);
  //   formDataToSend.append("ifscCode", formData.ifscCode);
  //   formDataToSend.append("bankName", formData.bankName);
  //   formDataToSend.append("bankBranchName", formData.bankBranchName);
  //   formDataToSend.append("Udise_Code", formData.Udise_Code);
  //   // console.log(formDataToSend, "PAYLOAD");

  //   if (formData.attachments[0]) {
  //     formDataToSend.append(
  //       "attachments",
  //       formData.attachments[0],
  //       formData.attachments[0].name
  //     );
  //   }

  //   // Confirmation alert before making the API call
  //   const isConfirmed = window.confirm(
  //     "Please review all your details carefully before proceeding. Once you submit, you will not be able to make any further edits."
  //   );
  //   if (!isConfirmed) {
  //     setFinalSubmit(false);
  //     return;
  //   }

  //   try {
  //     const response = await api.updatePatchData(
  //       `/update/student/register/${profileId}/${studentId}/${action}/${Key_Key}`,
  //       formDataToSend
  //     );
  //     if (response.status === 440) {
  //       setFinalSubmit(false);
  //       alert("Session Expired");
  //       dispatch(logoutUser());
  //       window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
  //     }
  //     if (response.status === 400) {
  //       setFinalSubmit(false);
  //       setIsError(true);
  //       let errorMessage = await response.message;
  //       setIsResponse(errorMessage);
  //     }
  //     if (!response.status === true) {
  //       // console.log("response--", await response.json())
  //       let errorMsg = await response.json();
  //       setFinalSubmit(false);
  //       setIsResponse(errorMsg.message);
  //       // console.log("response from update user---",response)
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     if (response.status === 200 || response.status === 201) {
  //       alert("Your Record Submitted Successfully");
  //       setFinalSubmit(false);
  //       // window.location.reload();
  //       fetchStudentProfiles();
  //     }
  //     setIsResponse(response.message);
  //   } catch (error) {
  //     console.error("Error updating student profile:", error);
  //     setIsError(true);
  //     setFinalSubmit(false);
  //   }
  // };

  // const updateStudentProfile = async (studentId: string, action: string) => {
  //   setIsError(false);
  //   setFinalSubmit(true);

  //   const invalidFields = Object.entries(formData).filter(
  //     ([key, value]) => typeof value === "object" && !value.isValid
  //   );
  //   // console.log(invalidFields, "INPUT");

  //   // if(!formData.height.isValid ) {
  //   //   setFinalSubmit(false);
  //   //   return alert("Invalid input for Height (in cm).")
  //   // }

  //   if (!formData.studentName.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Player Name");
  //   }

  //   if (!formData.aadharNumber.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Aadhar Number");
  //   }

  //   if (!formData.fatherName.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Player's Father Name");
  //   }

  //   if (!formData.motherName.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Player's Mother Name");
  //   }

  //   if (!formData.mobileNumber.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Parent/Guardian Mobile Number");
  //   }

  //   if (!formData.email.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Email");
  //   }

  //   if (!formData.height.isValid || formData.height.value === "0") {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Height (in cm).");
  //   }

  //   if (!formData.weight.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Weight (in Kg).");
  //   }

  //   if (!formData.verticalJump.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Vertical Jump (in cm).");
  //   }
  //   if (!formData.thirtyMFlingStarts.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for 30 Mtr sprint(in sec.");
  //   }
  //   if (!formData.footballBallThrow5No.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Football Throw ( in meter).");
  //   }
  //   if (!formData.shuttleRun10MX6.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for 6X10 Mtr shuttle run (in second).");
  //   }
  //   if (!formData.standingBroadJump.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for Standing Broad jump(in meter).");
  //   }
  //   if (!formData.eightHundredMetersRun.isValid) {
  //     setFinalSubmit(false);
  //     return alert("Invalid input for 800 mts Run ( in mm:ss:msms).");
  //   }

  //   // if (invalidFields.length > 0) {
  //   //   setIsError(true);
  //   //   return;
  //   // }

  //   // const payload = {
  //   //   studentId: formData.studentId,
  //   //   selectedInTeam: formData.selectedInTeam,
  //   //   studentName: formData.studentName.value,
  //   //   gender: formData.gender.value,
  //   //   aadharNumber: formData.aadharNumber.value,
  //   //   fatherName: formData.fatherName.value,
  //   //   motherName: formData.motherName.value,
  //   //   dob: formData.dob.value,
  //   //   caldob: formData.caldob.value,
  //   //   ageCategory: formData.ageCategory,
  //   //   mobileNumer: formData.mobileNumber.value,
  //   //   email: formData.email.value,
  //   //   accountNumber: formData.accountNumber,
  //   //   level: formData.level,
  //   //   gameName: formData.gameName,
  //   //   height: formData.height.value,
  //   //   weight: formData.weight.value,
  //   //   thirtyMFlingStarts: formData.thirtyMFlingStarts.value,
  //   //   standingBroadJump: formData.standingBroadJump.value,
  //   //   shuttleRun10MX6: formData.shuttleRun10MX6.value,
  //   //   verticalJump: formData.verticalJump.value,
  //   //   footballBallThrow5No: formData.footballBallThrow5No.value,
  //   //   eightHundredMetersRun: formData.eightHundredMetersRun.value,
  //   //   accountHolderName: formData.accountHolderName,
  //   //   ifscCode: formData.ifscCode,
  //   //   bankName: formData.bankName,
  //   //   bankBranchName: formData.bankBranchName,
  //   //   Udise_Code: formData.Udise_Code,
  //   // };

  //   const formDataToSend = new FormData();

  //   // Append each piece of state data to the FormData object
  //   formDataToSend.append("studentId", formData.studentId);
  //   formDataToSend.append("selectedInTeam", formData.selectedInTeam);
  //   formDataToSend.append("studentName", formData.studentName.value);
  //   formDataToSend.append("gender", formData.gender.value);
  //   formDataToSend.append("aadharNumber", formData.aadharNumber.value);
  //   formDataToSend.append("fatherName", formData.fatherName.value);
  //   formDataToSend.append("motherName", formData.motherName.value);
  //   formDataToSend.append("dob", formData.dob.value);
  //   formDataToSend.append("caldob", formData.caldob.value);
  //   formDataToSend.append("ageCategory", formData.ageCategory);
  //   formDataToSend.append("mobileNumber", formData.mobileNumber.value);
  //   formDataToSend.append("email", formData.email.value);
  //   formDataToSend.append("accountNumber", formData.accountNumber);
  //   formData.level.forEach((level, index) => {
  //     formDataToSend.append(`level[${index}]`, level);
  //   });

  //   // formData.gameName.forEach((game, index) => {
  //   //   formDataToSend.append(`gameName[${index}]`, game);
  //   // });
  //   // console.log("formData.gameName--------------", formData.gameName);

  //   // formData.gameName.forEach((game, index) => {
  //   //   const rank = ""; // You may need to include rank if it's part of the schema
  //   //   formDataToSend.append(`gameName[${index}][game]`, game);
  //   //   formDataToSend.append(`gameName[${index}][rank]`, rank); // If rank is relevant
  //   // });

  //   formData.gameName.forEach((gameObj: Game, index: number) => {
  //     const game = gameObj.game || ""; // Get the game name
  //     const rank = gameObj.rank || ""; // Get the rank or default to an empty string

  //     // Append the game name and rank to the FormData
  //     formDataToSend.append(`gameName[${index}][game]`, game);
  //     formDataToSend.append(`gameName[${index}][rank]`, rank);
  //   });

  //   formDataToSend.append("height", formData.height.value);
  //   formDataToSend.append("weight", formData.weight.value);
  //   formDataToSend.append(
  //     "thirtyMFlingStarts",
  //     formData.thirtyMFlingStarts.value
  //   );
  //   formDataToSend.append(
  //     "standingBroadJump",
  //     formData.standingBroadJump.value
  //   );
  //   formDataToSend.append("shuttleRun10MX6", formData.shuttleRun10MX6.value);
  //   formDataToSend.append("verticalJump", formData.verticalJump.value);
  //   formDataToSend.append(
  //     "footballBallThrow5No",
  //     formData.footballBallThrow5No.value
  //   );
  //   formDataToSend.append(
  //     "eightHundredMetersRun",
  //     formData.eightHundredMetersRun.value
  //   );
  //   if (
  //     formData.shuttleRun10MX6.value === "" ||
  //     formData.thirtyMFlingStarts.value === "" ||
  //     formData.verticalJump.value === "" ||
  //     formData.standingBroadJump.value === "" ||
  //     formData.weight.value === "" ||
  //     formData.height.value === "" ||
  //     formData.eightHundredMetersRun.value === ""
  //   ) {
  //     setIsError(true);
  //     setFinalSubmit(false);
  //     let errorMessage =
  //       "Please enter relevant data in each of the fields above";
  //     setIsResponse(errorMessage);
  //     return;
  //   }
  //   formDataToSend.append("accountHolderName", formData.accountHolderName);
  //   formDataToSend.append("ifscCode", formData.ifscCode);
  //   formDataToSend.append("bankName", formData.bankName);
  //   formDataToSend.append("bankBranchName", formData.bankBranchName);
  //   formDataToSend.append("Udise_Code", formData.Udise_Code);
  //   // console.log(formDataToSend, "PAYLOAD");

  //   if (formData.attachments[0]) {
  //     formDataToSend.append(
  //       "attachments",
  //       formData.attachments[0],
  //       formData.attachments[0].name
  //     );
  //   }

  //   // Confirmation alert before making the API call
  //   const isConfirmed = window.confirm(
  //     "Please review all your details carefully before proceeding. Once you submit, you will not be able to make any further edits."
  //   );
  //   if (!isConfirmed) {
  //     setFinalSubmit(false);
  //     return;
  //   }

  //   try {
  //     const response = await api.updatePatchData(
  //       `/update/student/register/${profileId}/${studentId}/${action}/${Key_Key}`,
  //       formDataToSend
  //     );
  //     if (response.status === 440) {
  //       setFinalSubmit(false);
  //       alert("Session Expired");
  //       dispatch(logoutUser());
  //       window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
  //     }
  //     if (response.status === 400) {
  //       setFinalSubmit(false);
  //       setIsError(true);
  //       let errorMessage = await response.message;
  //       setIsResponse(errorMessage);
  //     }
  //     if (!response.status === true) {
  //       // console.log("response--", await response.json())
  //       let errorMsg = await response.json();
  //       setFinalSubmit(false);
  //       setIsResponse(errorMsg.message);
  //       // console.log("response from update user---",response)
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     if (response.status === 200 || response.status === 201) {
  //       alert("Your Record Submitted Successfully");
  //       setFinalSubmit(false);
  //       // window.location.reload();
  //       fetchStudentProfiles();
  //     }
  //     setIsResponse(response.message);
  //   } catch (error) {
  //     console.error("Error updating student profile:", error);
  //     setIsError(true);
  //     setFinalSubmit(false);
  //   }
  // };

  // Handle file drop
  const onDrop = (acceptedFiles: File[]) => {
    setFormData({
      ...formData,
      attachments: [acceptedFiles[0]], // Only store the first file
      // attachments: [...formData.attachments, ...acceptedFiles],   //for multi file attachments
    });
    setIsError(false);
  };

  // Remove file from attachments
  const removeFile = (index: number) => {
    const newFiles = [...formData.attachments];
    newFiles.splice(index, 1);
    setFormData({ ...formData, attachments: newFiles });
  };

  // Drag and drop configuration
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".jpg,.jpeg,.png,.pdf",
    maxFiles: 1, // Allow only one file to be uploaded
  });

  // console.log("availableGames ----from final submittion -",availableGames)


  console.log("formData.caldob.value---", formData.caldob.value)

  return (
    <Container>
      <Card className="my-4">
        <div
          style={{ textAlign: "left", marginTop: "20px", marginLeft: "10px" }}
        >
          <a href="/pages/battery-test">
            <button className="btn btn-primary">Back</button>
          </a>
        </div>
        <Card.Body>
          <h2
            className="header-title mt-4 mb-4 p-2 text-center fs-19"
            style={{ border: "2px solid grey", borderRadius: "5px" }}
          >
            Player Profile Details
          </h2>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formStudentName">
              <Col xs={12} md={4} className="pb-3 pb-md-0 order-1 order-md-3">
                {Array.isArray(formData.photo) && formData.photo.length > 0 ? (
                  <img
                    src={
                      formData.photo[formData.photo.length - 1]?.fileUrl ?? "#" // Fallback URL if fileUrl is undefined
                    }
                    alt={
                      formData.photo[formData.photo.length - 1]?.fileName ??
                      "Image preview"
                    }
                    style={{
                      maxWidth: "12em", // Ensures the photo fits within the column
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                ) : (
                  <p style={{ color: "red" }}>No images available.</p>
                )}
                {formData?.updatable && (
                  <UpdatePlayerImageInProfile id={studentId} />
                )}
                {/* <Button>Update Photo</Button> */}
              </Col>

              <Col xs={12} md={8} className="order-2">
                <Form.Label column md={4} className="text-md-start">
                  Player's Full Name <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="studentName"
                  value={formData.studentName.value}
                  onChange={handleChange}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  isInvalid={!!errors.studentName}
                />
                {!formData.studentName.isValid && (
                  <p style={{ color: "red" }}>
                    Invalid input. Please enter a valid Player Name.
                  </p>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formGender">
              <Form.Label column md={4} className="text-md-start">
                Gender <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <div>
                  <Form.Check
                    inline
                    label="Girl"
                    name="gender"
                    type="radio"
                    id="genderFemale"
                    value="Female"
                    checked={formData.gender.value === "Female"}
                    onChange={handleChange}
                    disabled={!formData.updatable} // Conditionally disable based on updatable prop
                  />
                  <Form.Check
                    inline
                    label="Boy"
                    name="gender"
                    type="radio"
                    id="genderMale"
                    value="Male"
                    checked={formData.gender.value === "Male"}
                    onChange={handleChange}
                    disabled={!formData.updatable} // Conditionally disable based on updatable prop
                  />
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formAadharNumber">
              <Form.Label column md={4} className="text-md-start">
                Aadhar Number <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="aadharNumber"
                  value={formData.aadharNumber.value}
                  onChange={handleChange}
                  maxLength={12}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />

                {!formData.aadharNumber.isValid && (
                  <p style={{ color: "red" }}>
                    Invalid input. Please enter Aadhar Number.
                  </p>
                )}
              </Col>
            </Form.Group>
            {/* {formData?.updatable && (
              <Form.Group as={Row} className="mb-3" controlId="fileUrl">
                <Form.Label column md={4} className="text-md-start">
                  Upload Aadhar [
                  <span style={{ color: "#339900" }}>
                    Front and Back side in one pdf
                  </span>
                  ]<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col md={8}>
                  <div
                    {...getRootProps()}
                    className={`dropzone border rounded p-1 text-center ${
                      isDragActive ? "border-primary" : "border-secondary"
                    }`}
                  >
                    <input
                      {...getInputProps()}
                      required
                      readOnly={!formData?.updatable}
                    />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>
                        Drag and drop or click to select files (PDF) (max size
                        200kb).
                      </p>
                    )}
                    <i
                      className="bi bi-upload"
                      style={{ fontSize: "1rem" }}
                    ></i>
                  </div>
                  {isError && <p style={{ color: "red" }}>File is required</p>}
                  <FilePreview
                    files={formData?.attachments || []}
                    removeFile={removeFile}
                  />
                </Col>
              </Form.Group>
            )} */}

            {/* adhar link */}

            {/* <Form.Group as={Row} className="mb-3" controlId="formDocument">
              <Form.Label column md={4} className="text-md-start">
                Uploaded Aadhaar Doc Link{" "}
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                {Array.isArray(formData.document) &&
                formData.document.length > 0 ? (
                  <a
                    href={
                      formData.document[formData.document.length - 1]
                        ?.fileUrl ?? "#"
                    } // Fallback URL if fileUrl is undefined
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    {formData.document[formData.document.length - 1]
                      ?.fileName ?? "doc link"}
                  </a>
                ) : (
                  <p style={{ color: "red" }}>No documents available.</p>
                )}
                {formData?.updatable && (
                  <UpdatePlayerAadharImage id={studentId} />
                )}
              </Col>
            </Form.Group> */}

            <Form.Group as={Row} className="mb-3" controlId="formDocument">
              <Form.Label column md={4} className="text-md-start">
                Uploaded Aadhaar Doc Link{" "}
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              {Array.isArray(formData?.liveAadharCapturedImageData) &&
              formData.liveAadharCapturedImageData.length <= 0 ? (
                <Col md={8}>
                  {Array.isArray(formData.document) &&
                  formData.document.length > 0 ? (
                    <a
                      href={
                        formData.document[formData.document.length - 1]
                          ?.fileUrl ?? "#"
                      } // Fallback URL if fileUrl is undefined
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "blue",
                        marginRight: "20px",
                      }}
                    >
                      {formData.document[formData.document.length - 1]
                        ?.fileName ?? "doc link"}
                    </a>
                  ) : (
                    <p style={{ color: "red" }}>No documents available.</p>
                  )}
                  {formData?.updatable && (
                    <UpdatePlayerAadharImage id={studentId} />
                  )}
                </Col>
              ) : (
                <Col md={8}>
                  {Array.isArray(formData.liveAadharCapturedImageData) &&
                  formData.liveAadharCapturedImageData.length > 0 ? (
                    <>
                      <a
                        href={
                          formData.liveAadharCapturedImageData[
                            formData.liveAadharCapturedImageData.length - 1
                          ]?.fileUrlOfAadharFront ?? "#"
                        } // Fallback URL if fileUrl is undefined
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Front Image Of Aadhar
                      </a>
                      <span style={{ margin: "25px 10px 5px 30px" }}>And</span>
                      <a
                        href={
                          formData.liveAadharCapturedImageData[
                            formData.liveAadharCapturedImageData.length - 1
                          ]?.fileUrlOfAadharBack ?? "#"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Back Image Of Aadhar
                      </a>
                    </>
                  ) : (
                    <p style={{ color: "red" }}>No documents available.</p>
                  )}
                  {formData?.updatable && (
                    <UpdatePlayerAadharImage id={studentId} />
                  )}
                </Col>
              )}
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formMotherName">
              <Form.Label column md={4} className="text-md-start">
                Mother's Full Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="motherName"
                  value={formData.motherName.value}
                  onChange={handleChange}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
                {!formData.motherName.isValid && (
                  <p style={{ color: "red" }}>
                    Invalid input. Please enter a valid Player's Mother Name.
                  </p>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formFatherName">
              <Form.Label column md={4} className="text-md-start">
                Father's Full Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="fatherName"
                  value={formData.fatherName.value}
                  onChange={handleChange}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
                {!formData.fatherName.isValid && (
                  <p style={{ color: "red" }}>
                    Invalid input. Please enter a valid Player's Father Name.
                  </p>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formDOB">
              <Form.Label column md={4} className="text-md-start">
                Date of Birth <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="date"
                  name="dob"
                  value={formData.dob.value}
                  onChange={handleChange}
                  required
                  ref={dobRef}
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  isInvalid={!!errors.dob}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dob}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formAgeAsOn">
              <Form.Label column md={4} className="text-md-start">
                Age as on 31st Dec 2024
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="caldob"
                  value={
                    formData.caldob.value
                  }
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formCategory">
              <Form.Label column md={4} className="text-md-start">
                Category
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="ageCategory"
                  value={formData?.ageCategory}
                  readOnly
                  style={{ backgroundColor: "#e9ecef" }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formMobileNumber">
              <Form.Label column md={4} className="text-md-start">
                Parent/Guardian Mobile Number{" "}
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="mobileNumber"
                  value={formData?.mobileNumber?.value}
                  onChange={handleChange}
                  maxLength={10}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
                {!formData.mobileNumber.isValid && (
                  <p style={{ color: "red" }}>
                    Invalid input. Please enter valid Parent/Guardian Mobile
                    Number.
                  </p>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formEmail">
              <Form.Label column md={4} className="text-md-start">
                E-Mail Id
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email.value}
                  onChange={handleChange}
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
                {!formData.email.isValid && (
                  <p style={{ color: "red" }}>
                    Invalid input. Please enter valid email.
                  </p>
                )}
              </Col>
            </Form.Group>

            {/* <Form.Group as={Row} className="mb-3" controlId="formGameName">
              <Form.Label column md={4} className="text-md-start">
                Select Your Game <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Select
                  isMulti
                  name="gameName"
                  options={availableGames}
                  value={availableGames.filter((game) =>
                    formData.gameName.includes(game.value)
                  )}
                  onChange={handleGameChange}
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  isDisabled={!formData?.updatable} // Conditionally disable based on updatable prop
                />
                <small className="form-text text-muted">
                  In athletics, a maximum of two events can be selected.
                </small>
              </Col>
            </Form.Group> */}

            <Form.Group as={Row} className="mb-3" controlId="formGameName">
              <Form.Label column md={4} className="text-md-start">
                Select Your Game <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Select
                  isMulti
                  name="gameName"
                  options={availableGames} // Assuming availableGames is an array of game options with { value: string, label: string }
                  value={availableGames.filter((gameOption) =>
                    formData.gameName.some(
                      (selectedGame: Game) =>
                        selectedGame.game === gameOption.value
                    )
                  )}
                  onChange={handleGameChange}
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  isDisabled={!formData?.updatable} // Conditionally disable based on updatable prop
                />
                {/* <small className="form-text text-muted">
                  In athletics, a maximum of two events can be selected.
                </small> */}
              </Col>
            </Form.Group>

            {formData?.updatable === true && (
              <Row>
                <Col className="text-end">
                  <Button
                    onClick={() =>
                      updateStudentProfile(formData?._id ?? "", "save")
                    }
                    className="btn btn-primary"
                    disabled={saveButton}
                  >
                    {saveButton ? "Saving....." : "Save"}
                  </Button>
                </Col>
              </Row>
            )}

            <h2
              className="header-title mt-4 mb-4 p-2 text-center fs-19"
              style={{ border: "2px solid grey", borderRadius: "5px" }}
            >
              Battery Test Details
            </h2>
            <Row className="mb-3">
              {/* Height (in cm) */}
              <Col md={3}>
                <Form.Group controlId="formHeight">
                  <Form.Label>Height (in cm)</Form.Label>
                  <Form.Control
                    type="text"
                    name="height"
                    value={formData.height.value}
                    onChange={handleChange}
                    style={getInputStyle(formData.height.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                    required={!formData?.updatable}
                  />
                  {!formData.height.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              {/* Weight (in Kg) */}
              <Col md={3}>
                <Form.Group controlId="formWeight">
                  <Form.Label>Weight (in Kg)</Form.Label>
                  <Form.Control
                    type="text"
                    name="weight"
                    value={formData.weight.value}
                    onChange={handleChange}
                    style={getInputStyle(formData.weight.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                    required={!formData?.updatable}
                  />
                  {!formData.weight.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              {/*  30 Mtr sprint(in sec.) */}
              <Col md={3}>
                <Form.Group controlId="formThirtyMFlingStarts">
                  <Form.Label>30 Mtr sprint(in sec.)</Form.Label>
                  <Form.Control
                    type="text"
                    name="thirtyMFlingStarts"
                    value={formData.thirtyMFlingStarts.value}
                    onChange={handleChange}
                    style={getInputStyle(formData.thirtyMFlingStarts.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.thirtyMFlingStarts.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              {/* 6X10 Mtr shuttle run (in second) */}
              <Col md={3}>
                <Form.Group controlId="formShuttleRun10MX6">
                  <Form.Label>6X10 Mtr shuttle run (in second)</Form.Label>
                  <Form.Control
                    type="text"
                    name="shuttleRun10MX6"
                    value={formData.shuttleRun10MX6.value}
                    onChange={handleChange}
                    required
                    style={getInputStyle(formData.shuttleRun10MX6.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.shuttleRun10MX6.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              {/* Vertical Jump (in cm) */}
              <Col md={3}>
                <Form.Group controlId="formVerticalJump">
                  <Form.Label>Vertical Jump (in cm)</Form.Label>
                  <Form.Control
                    type="text"
                    name="verticalJump"
                    value={formData.verticalJump.value}
                    onChange={handleChange}
                    style={getInputStyle(formData.verticalJump.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.verticalJump.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              {/* Standing Broad jump(in meter) */}
              <Col md={3}>
                <Form.Group controlId="formStandingBroadJump">
                  <Form.Label>Standing Broad jump(in cm)</Form.Label>
                  <Form.Control
                    type="text"
                    name="standingBroadJump"
                    value={formData.standingBroadJump.value}
                    onChange={handleChange}
                    required
                    style={getInputStyle(formData.standingBroadJump.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.standingBroadJump.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>

              {/* Football Throw ( in meter) */}
              <Col md={3}>
                <Form.Group controlId="formFootballBallThrow5No">
                  <Form.Label>Football Throw ( in meter)</Form.Label>
                  <Form.Control
                    type="text"
                    name="footballBallThrow5No"
                    value={formData.footballBallThrow5No.value}
                    onChange={handleChange}
                    required={true}
                    style={getInputStyle(formData.footballBallThrow5No.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.footballBallThrow5No.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              {/* 800 mts Run ( in mm:ss:msms) */}
              <Col md={3}>
                <Form.Group controlId="formEightHundredMetersRun">
                  <Form.Label>
                    800 mts Run ( in mm:ss:msms) {formData?.updatable}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="eightHundredMetersRun"
                    value={formData.eightHundredMetersRun.value}
                    onChange={handleChange}
                    pattern="^\d*\.?\d{0,2}$"
                    style={getInputStyle(
                      formData.eightHundredMetersRun.isValid
                    )}
                    placeholder="MM:SS:SSS"
                    required={true}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.eightHundredMetersRun.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {isError && (
              <Alert variant="danger" className="my-2">
                {isResponse}
              </Alert>
            )}

            {/* have to be uncommited  */}
            {/* {formData?.updatable === true && (
              <Row>
                <Col className="text-end">
                  <Button
                    onClick={() =>
                      updateStudentProfile(formData?._id ?? "", "submit")
                    }
                    className="btn btn-primary"
                    disabled={finalSubmit}
                  >
                    {finalSubmit ? "submitting....." : "Final Submission"}
                  </Button>
                </Col>
              </Row>
            )} */}

          
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProfileStudentRegistrationForm;
