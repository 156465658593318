import React, { useEffect, useState } from "react";
import AllRoutes from "./routes/Routes";
import { configureFakeBackend } from "./helpers";
import "./assets/scss/Theme.scss"; // Import theme
import DetectSessionTimeOut from "./pages/dashboard/schooldashboard/SessionTimeOut/DetectSessionTimeOut";
// import { useNavigate } from "react-router-dom";
// import { API_BASE_URL } from "./apiservices/apiService";
// import Maintenance from "./pages/other/Maintenance";

// Initialize fake backend
configureFakeBackend();

const App = () => {
  // const [isBackendDown, setIsBackendDown] = useState(false);
  // const [isCheckingStatus, setIsCheckingStatus] = useState(true);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const checkBackendHealth = async () => {
  //     setIsCheckingStatus(true);
  //     try {
  //       const response = await fetch(`${API_BASE_URL}/health`);
  //       if (!response.ok) {
  //         throw new Error(`HTTP error: ${response.status}`);
  //       }
  //       const result = await response.json();
  //       if (result.status !== "UP") {
  //         throw new Error("Backend status is not UP");
  //       }
  //       setIsBackendDown(false);
  //     } catch (error) {
  //       console.error("Backend health check failed:", error);
  //       setIsBackendDown(true);
  //       navigate("/maintenance");
  //     } finally {
  //       setIsCheckingStatus(false);
  //     }
  //   };

  //   checkBackendHealth();
  //   const interval = setInterval(checkBackendHealth, 60000);

  //   return () => clearInterval(interval); // Cleanup
  // }, [navigate]);

  return (
    <React.Fragment>
      <AllRoutes />
      <DetectSessionTimeOut />
      {/* {!isBackendDown ? <AllRoutes /> : <Maintenance />} */}
    </React.Fragment>
  );
};

export default App;
